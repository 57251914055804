import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import 'styles/pages/allUsersPage.scss'
import { useSnackbar } from 'notistack'
import { userController } from 'controllers'
import { userInterface } from 'interfaces'
import UserTable from './UserTable'
import { Button } from 'components/generic/Button'

function AllUsersPage({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [search, setSearch] = useState('')
  const [users, setUsers] = useState<userInterface.IUser[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  async function getAllUsers() {
    try {
      const usersFetch = await userController.getAllUsers()
      setUsers(usersFetch.items)
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }

    setIsLoading(false)
  }

  async function getUsersPaginated() {
    try {
      const usersFetch =
        search.length > 2
          ? await userController.getUsersByName({ name: search, page: page + 1 })
          : await userController.getAllUsers(page + 1)
      if (users) setUsers([...users, ...usersFetch.items])
      setPage(page + 1)
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  async function findUsers() {
    try {
      const usersFetch = await userController.getUsersByName({ name: search })
      return setUsers(usersFetch.items)
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    setPage(1)
    if (search.length > 2) findUsers()
    else if (search.length === 0) getAllUsers()
  }, [search])

  useEffect(() => {
    getAllUsers()
  }, [])

  return isLoading ? (
    <div className="allUsersPage">
      <div className="loading-spinner">
        <div className="spinner"></div>
        <p>Loading users...</p>
      </div>
    </div>
  ) : (
    <div className='allUsersPage'>
      <h1 className='title'>All Users</h1>
      <input 
        className='searchField' 
        value={search} 
        onChange={(v) => setSearch(v.target.value)}
        placeholder="Search users..."
        aria-label="Search users"
      />
      {users && users.length > 0 ? (
        <>
          <UserTable users={users} />
          <Button onClick={() => getUsersPaginated()}>Load More Users</Button>
        </>
      ) : (
        <p className="no-results">No users found</p>
      )}
    </div>
  )
}

export default AllUsersPage
