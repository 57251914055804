import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import 'styles/pages/appPage.scss'
import { appController } from 'controllers'
import { appInterface } from 'interfaces'
import { AppPlatforms } from 'components/apps'
import { IPlatform, IPlatformsMini } from 'interfaces/appInterface'

function AppPage() {
  const params = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const uuid: string = params.uuid || ''
  const [app, setApp] = useState<appInterface.IApp>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showFullDescription, setShowFullDescription] = useState(false);

  async function getAppById() {
    let appSearch: appInterface.IApp
    try {
      appSearch = await appController.getAppById(uuid)
      setApp(appSearch)
      return setIsLoading(false)
    } catch (error: any) {
      return navigate('/home')
    }
  }

  async function changeAppStatus({ status }: { status: appInterface.Status }) {
    try {
      if (app) await appController.changeAppStatus({ uuid: app?.uuid, status })
      await getAppById()
      enqueueSnackbar('Status has been updated', { variant: 'success' })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  async function deleteApp() {
    try {
      if (app) await appController.deleteApp(app?.uuid)
      enqueueSnackbar('App has been deleted', { variant: 'success' })
      return navigate('/')
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  function getParsedPlatforms(): IPlatform[] {
    if (!app?.platforms) return []
    const platformsParsed = Object.keys(app.platforms)
      .filter((key) => key !== 'requests')
      .map((platform) => app.platforms[platform as keyof IPlatformsMini] as IPlatform)
    return platformsParsed || []
  }

  const wordCount = (str: string) => str.trim().split(/\s+/).length;
  const isLongDescription = (desc: string) => wordCount(desc) > 30;
  
  const truncateDescription = (desc: string) => {
    const words = desc.trim().split(/\s+/);
    return words.slice(0, 30).join(' ') + '...';
  };

  useEffect(() => {
    getAppById()
  }, [])

  return isLoading || !app ? (
    <h1>Loading...</h1>
  ) : (
    <div className='appPage'>
      {app.status === appInterface.Status.Reported && (
        <span className='warning'>This app has been reported multiple times and requires review</span>
      )}
      
      <div className='header'>
        <div className='headerMain'>
          <img className='appImage' src={app.imageUrl} alt={app.title} />
          <div className='titleSection'>
            <h1 className='title'>{app.title}</h1>
            <p className='sellerName'>Developer: {app.sellerName}</p>
            {app.status && <span className='status'>{app.status}</span>}
            <div className='appDescription'>
              {app?.description && (
                <>
                  <p>
                    {isLongDescription(app.description) && !showFullDescription
                      ? truncateDescription(app.description)
                      : app.description}
                  </p>
                  {isLongDescription(app.description) && !showFullDescription && (
                    <button 
                      className='see-more-btn'
                      onClick={() => setShowFullDescription(true)}
                    >
                      See more
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className='actionButtons'>
          {app.status === appInterface.Status.Approved ? (
            <button
              type='button'
              onClick={() => changeAppStatus({ status: appInterface.Status.Banned })}
              className='cta ban'
            >
              Ban App
            </button>
          ) : (
            <button
              type='button'
              onClick={() => changeAppStatus({ status: appInterface.Status.Approved })}
              className='cta'
            >
              Approve App
            </button>
          )}
          <button type='button' onClick={() => deleteApp()} className='cta delete'>
            Delete App
          </button>
          <Link to={`/app/${uuid}/edit`}>
            <button className='cta' type='button'>
              Edit App
            </button>
          </Link>
        </div>
      </div>

      <AppPlatforms
        appId={app.uuid}
        refreshApp={getAppById}
        platformRequests={app.platforms.requests}
        platforms={getParsedPlatforms()}
      />

      <div className='infoGrid'>
        <div className='infoCard'>
          <div className='label'>Price</div>
          <div className='value'>
            {app.platforms?.itunes?.price && app.platforms?.itunes?.price > 0 ? (
              `$${app.platforms.itunes?.price}`
            ) : (
              <span>Free</span>
            )}
          </div>
        </div>

        <div className='infoCard'>
          <div className='label'>Categories</div>
          <div className='value'>{Array.isArray(app.categories) ? app.categories.join(', ') : app.categories}</div>
        </div>

        <div className='infoCard'>
          <div className='label'>Rating</div>
          <div className='value'>
            {app.rating} ({app.ratingCount} reviews)
          </div>
        </div>

        <div className='infoCard'>
          <div className='label'>Release Date</div>
          <div className='value'>{app.releaseDate}</div>
        </div>

        <div className='infoCard'>
          <div className='label'>Version</div>
          <div className='value'>{app.version}</div>
        </div>
      </div>
    </div>
  )
}

export default AppPage
