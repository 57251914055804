import { attributeInterface } from 'interfaces'
import { errorHelper } from 'helpers'
import { getAuthInstance, getInstance } from 'utils/axiosInstance'

const backendURL = process.env.REACT_APP_BACKEND_URL
const instance = getInstance(`${backendURL}/attributes`)
const instanceAuth = getAuthInstance(`${backendURL}/attributes`)

export async function getCustomAttributes(): Promise<attributeInterface.IAttribute[]> {
  try {
    const { data } = await instance.get('/')
    return data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function addCustomAttribute(
  attribute: string,
  adminOnly: boolean,
): Promise<attributeInterface.IAttribute> {
  try {
    const { data } = await instanceAuth.post('/', { name: attribute, adminOnly })
    return data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function updateCustomAttribute(
  uuid: string,
  attribute: string,
  adminOnly: boolean,
): Promise<attributeInterface.IAttribute[]> {
  try {
    const { data } = await instanceAuth.put('/', { uuid, name: attribute, adminOnly })
    return data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function deleteAttribute(uuid: string): Promise<attributeInterface.IAttribute[]> {
  try {
    const { data } = await instanceAuth.delete('/', { data: JSON.stringify({ uuid }) })
    return data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}
