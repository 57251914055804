import React, { useState } from 'react'
import { Button } from '@mui/material'
import { subscriptionsController } from 'controllers'
import { AppSearch } from 'components/apps/AppSearch'
import { useSnackbar } from 'notistack'
import { IApp } from 'interfaces/appInterface'
import { InputField, SelectorField } from 'components/generic'
import { LabelValue } from 'interfaces/generic'
import { AppList } from 'components/apps'
import styles from './EditSubscription.module.scss'
import { currencyOptions } from 'utils/appConsts'
import {
  INewSubscription,
  SubscriptionInterval,
  SubscriptionPlan,
} from 'interfaces/subscriptionsInterface'

interface AddSubscriptionProps {
  reload?: () => void
  setMode: (mode: boolean) => void
}

export const AddSubscription = ({ reload, setMode }: AddSubscriptionProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [selectedApp, setSelectedApp] = useState<IApp | undefined>(undefined)
  const [subscriptionData, setSubscriptionData] = useState<INewSubscription>({
    appId: '',
    cost: undefined,
    currency: 'USD',
    interval: SubscriptionInterval.Month,
    plan: SubscriptionPlan.Paid,
    note: '',
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    setSubscriptionData((s) => ({ ...s, [e.target.name]: e.target.value }))
  }

  const addSubscription = async () => {
    try {
      await subscriptionsController.createSubscription({ ...subscriptionData })

      if (reload) reload()
      enqueueSnackbar('Subscription added', { variant: 'success' })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
    setMode(false)
  }

  const handleSetSubscriptionApp = (app?: IApp) => {
    setSubscriptionData((s) => ({ ...s, appId: app?.uuid || '' }))
    setSelectedApp(app)
  }

  const billingIntervals = {
    [SubscriptionInterval.Month]: { label: 'Monthly', value: SubscriptionInterval.Month },
    [SubscriptionInterval.Year]: { label: 'Yearly', value: SubscriptionInterval.Year },
  }

  return (
    <div className={styles.editSubscription}>
      {selectedApp ? (
        <AppList
          deleteApp={() => handleSetSubscriptionApp(undefined)}
          apps={selectedApp ? [selectedApp] : []}
        />
      ) : (
        <AppSearch existingAppIds={[]} saveApp={handleSetSubscriptionApp} />
      )}
      {subscriptionData.plan === SubscriptionPlan.Paid && (
        <div className={styles.billingInputs}>
          <InputField
            name='cost'
            label='Cost'
            value={subscriptionData.cost}
            handleChange={handleChange}
            placeholder="0.00"
            type="currency"
          />
          <SelectorField
            name='currency'
            label='Currency'
            options={currencyOptions}
            value={{ label: subscriptionData.currency, value: subscriptionData.currency }}
            handleChange={(e: LabelValue) =>
              setSubscriptionData((s) => ({ ...s, currency: e.value }))
            }
          />
          <SelectorField
            name='interval'
            label='Billing Frequency'
            options={Object.values(billingIntervals)}
            value={billingIntervals[subscriptionData.interval]}
            handleChange={(e: LabelValue) =>
              setSubscriptionData((s) => ({ ...s, interval: e.value as SubscriptionInterval }))
            }
          />
        </div>
      )}
      <InputField
        name="note"
        label="Subscription Note"
        placeholder="Enter a note (up to 100 characters)"
        value={subscriptionData.note}
        handleChange={(e) => setSubscriptionData((s) => ({ ...s, note: e.target.value }))}
        maxLength={100}
      />
      <div className={styles.actions}>
        <Button 
          onClick={() => setMode(false)} 
          variant="outlined" 
          color="primary"
          sx={{ minWidth: '120px' }}
        >
          Cancel
        </Button>
        <Button
          onClick={addSubscription}
          variant="contained"
          color="primary"
          disabled={!subscriptionData.appId}
          sx={{ 
            minWidth: '120px',
            opacity: !subscriptionData.appId ? 0.5 : 1,
            backgroundColor: !subscriptionData.appId ? 'rgba(255, 255, 255, 0.12)' : undefined
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  )
}
