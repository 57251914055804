import React, { useEffect, useState } from 'react'
import { BsPencil, BsXLg } from 'react-icons/bs'
import { attributeInterface } from 'interfaces'

import styles from './AttributeList.module.scss'
import { Badge } from 'components/list'
import { AddEditAttribute } from './AddEditAttribute'

interface AttributeListProps {
  attributes: attributeInterface.IAttribute[]
  deleteAttribute: (attributeId: string) => void
  setCurrentAttributes: React.Dispatch<React.SetStateAction<attributeInterface.IAttribute[]>>
}

export const AttributeList: React.FC<AttributeListProps> = ({
  attributes,
  deleteAttribute,
  setCurrentAttributes,
}) => {
  const [editAttribute, setEditAttribute] = useState<attributeInterface.IAttribute | null>(null)

  return (
    <div className={styles.attributeList}>
      {attributes &&
        attributes.length > 0 &&
        attributes.map((attribute) => (
          <div key={attribute.uuid} className={styles.attributeComponent}>
            <p className={styles.name}>{attribute.name}</p>
            {attribute.adminOnly && <Badge label={'Admin Only'} />}
            <div className={styles.attributeActions}>
              <BsPencil
                onClick={() => setEditAttribute(attribute)}
                className={styles.attributeIcon}
              />
              <BsXLg
                onClick={() => {
                  deleteAttribute(attribute.uuid)
                }}
                className={styles.attributeIcon}
              />
            </div>
          </div>
        ))}

      <AddEditAttribute
        attributeState={{ editAttribute, setEditAttribute }}
        updateAttributes={setCurrentAttributes}
        addAttribute={(newAttribute: attributeInterface.IAttribute) =>
          setCurrentAttributes([...attributes, newAttribute])
        }
      />
    </div>
  )
}
