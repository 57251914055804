import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { BsPlusLg } from 'react-icons/bs'
import { useSnackbar } from 'notistack'
import { attributeController } from 'controllers'
import { CheckboxField, InputField } from 'components/generic'
import { IAttribute } from 'interfaces/attributeInterface'

import styles from './AddEditAttribute.module.scss'
import { Button } from 'components/generic/Button'
import { attributeInterface } from 'interfaces'

interface AddEditAttributeProps {
  addAttribute: (attr: IAttribute) => void
  updateAttributes: (attr: IAttribute[]) => void
  attributeState: {
    editAttribute: attributeInterface.IAttribute | null
    setEditAttribute: React.Dispatch<React.SetStateAction<attributeInterface.IAttribute | null>>
  }
}

export const AddEditAttribute: React.FC<AddEditAttributeProps> = ({
  addAttribute,
  attributeState,
  updateAttributes,
}) => {
  const { editAttribute: attribute, setEditAttribute: setAttribute } = attributeState
  const [isAdding, setIsAdding] = useState<boolean>(!!attribute)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [newAttribute, setNewAttribute] = useState<string>(attribute?.name ?? '')
  const [adminOnly, setAdminOnly] = useState<boolean>(attribute?.adminOnly ?? false)
  const { enqueueSnackbar } = useSnackbar()

  const addNewAttribute = async () => {
    try {
      setIsUpdating(true)
      if (attribute) {
        updateAttributes(
          await attributeController.updateCustomAttribute(attribute.uuid, newAttribute, adminOnly),
        )
        enqueueSnackbar('Attribute Updated', { variant: 'success' })
      } else if (newAttribute) {
        addAttribute(await attributeController.addCustomAttribute(newAttribute, adminOnly))
        enqueueSnackbar('Attribute Updated', { variant: 'success' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
    setNewAttribute('')
    setAttribute(null)
    setIsAdding(false)
    setIsUpdating(false)
  }

  useEffect(() => {
    if (attribute) {
      setIsAdding(true)
      setNewAttribute(attribute.name)
      setAdminOnly(attribute.adminOnly ?? false)
    }
  }, [attribute])

  return (
    <div className={styles.newAttribute}>
      {isAdding ? (
        <div className={styles.newAttributeBox}>
          <InputField
            name='attribute'
            label='Attribute'
            value={newAttribute}
            handleChange={(e) => setNewAttribute(e.target.value)}
          />
          <CheckboxField
            name='adminOnly'
            label='Admin Only'
            isChecked={adminOnly}
            handleChange={() => setAdminOnly(!adminOnly)}
          />
          <div className={styles.actions}>
            <Button
              className={styles.cancel}
              disabled={isUpdating}
              onClick={() => {
                setIsAdding(false)
                setAttribute(null)
              }}
            >
              Cancel
            </Button>
            <Button className={styles.save} disabled={isUpdating} onClick={addNewAttribute}>
              Save
            </Button>
          </div>
        </div>
      ) : (
        <BsPlusLg
          onClick={() => {
            setIsAdding(true)
          }}
          className={styles.attributeAddIcon}
        />
      )}
    </div>
  )
}
