/* eslint-disable no-constant-condition */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { BsXLg } from 'react-icons/bs'
import { appController } from 'controllers'
import { appInterface } from 'interfaces'
import LoadingSpinner from 'components/generic/loadingSpinner'

import 'styles/pages/pendingPlatforms.scss'

function AppReports({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [search, setSearch] = useState<string>('')
  const [apps, setApps] = useState<appInterface.IApp[]>([])
  const [page, setPage] = useState<number>(0)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const navigate = useNavigate()

  async function getReportedApps() {
    try {
      setIsLoading(true)
      const response = await appController.getReportedApps(page)

      if (!response?.apps?.length) {
        setHasMore(false)
        setIsLoading(false)
        return
      }

      // Use a Set to ensure uniqueness based on UUID
      const uniqueApps = new Set([...apps, ...response.apps].map((app) => JSON.stringify(app)))
      setApps(Array.from(uniqueApps).map((app) => JSON.parse(app)))
      setPage((prev) => prev + 1)
    } catch (error) {
      console.error('Error fetching reported apps:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const checkSearch = (title: string) => title.toLowerCase().includes(search.toLowerCase())

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  useEffect(() => {
    getReportedApps()
  }, [])

  const filteredApps = apps.filter((app) => checkSearch(app.title))

  if (isLoading && !apps.length) return <LoadingSpinner />

  return (
    <div className='container'>
      <h1>App Reports</h1>
      <input
        className='searchField'
        placeholder='Search apps...'
        value={search}
        onChange={(e) => {
          setSearch(e.target.value)
        }}
      />
      <div className='appList'>
        {filteredApps.length > 0 ? (
          filteredApps.map((app) => (
            <div key={`${app.uuid}-${app.title}`}>
              <div className='appComponent'>
                <Link className='appUrl' to={`/apps/app-reports/${app.uuid}`}>
                  <img className='appImage' src={app.imageUrl} alt={app.title} />
                </Link>
                <div className='textBox'>
                  <Link className='appUrl' to={`/apps/app-reports/${app.uuid}`}>
                    <p className='title'>{app.title}</p>
                  </Link>
                  <p className='description'>{app.description}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No reported apps found</p>
        )}
      </div>
      {hasMore && !isLoading && (
        <button className='cta' onClick={getReportedApps} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Load More'}
        </button>
      )}
    </div>
  )
}

export default AppReports
