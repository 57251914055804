/* eslint-disable no-constant-condition */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { BsXLg } from 'react-icons/bs'
import { appController } from 'controllers'
import { appInterface } from 'interfaces'

import 'styles/pages/pendingPlatforms.scss'

function PendingPlatforms({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [apps, setApps] = useState<appInterface.IApp[]>([])
  const navigate = useNavigate()

  async function getPendingApps() {
    try {
      setError('')
      setApps([]) // Reset apps when starting a new fetch

      const response = await appController.getApps()
      const appsData = Array.isArray(response) ? response : response?.apps

      if (!appsData || !appsData.length) {
        setIsLoading(false)
        return
      }

      // Filter apps with pending platform requests
      const pendingApps = appsData.filter((app: appInterface.IApp) => {
        if (!app.platforms?.requests) {
          return false
        }

        const hasPendingRequests = app.platforms.requests.some(
          (request: appInterface.IPlatform) => request.approved !== true,
        )
        return hasPendingRequests
      })

      setApps(pendingApps.map((app: appInterface.IApp) => ({ ...app, uuid: app.uuid })))
      setIsLoading(false)
    } catch (err: any) {
      console.error('Error in getPendingApps:', err)
      setError(err.message || 'Failed to load pending platforms')
      setIsLoading(false)
    }
  }

  const checkSearch = (title: string) => (search ? title.toLocaleLowerCase().match(search) : true)

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn])

  useEffect(() => {
    getPendingApps()
  }, [])

  if (isLoading) return <h1>Loading...</h1>

  if (error) return <h1 className='error'>{error}</h1>

  if (!apps.length) {
    return (
      <div className='container'>
        <h1>Pending Platforms</h1>
        <p className='no-results'>No pending platform requests found</p>
      </div>
    )
  }

  return (
    <div className='container'>
      <h1>Pending Platforms</h1>
      <input
        className='searchField'
        placeholder='Search platforms...'
        onChange={(e) => {
          setSearch(e.target.value.toLocaleLowerCase())
        }}
      />
      <div className='appList'>
        {/*  && app.platforms.requests?.length */}
        {apps &&
          apps.length > 0 &&
          apps.map((app) =>
            checkSearch(app.title) ? (
              <div key={app.uuid}>
                <div className='appComponent'>
                  <Link className='appUrl' to={`/apps/pending-platforms/${app.uuid}`}>
                    <img className='appImage' src={app.imageUrl} alt={app.title} />
                  </Link>
                  <div className='textBox'>
                    <Link className='appUrl' to={`/apps/pending-platforms/${app.uuid}`}>
                      <p className='title'>{app.title}</p>
                    </Link>
                    <p className='description'>{app.description}</p>
                  </div>
                </div>
              </div>
            ) : null,
          )}
      </div>
    </div>
  )
}

export default PendingPlatforms
