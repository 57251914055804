/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { subscriptionsController } from 'controllers'
import { AddSubscription } from 'components/subscriptions/AddSubscription'
import { Subscription } from 'components/subscriptions/Subscription'
import styles from './SuggestedSubscriptionsPage.module.scss'
import { SubscriptionsHeader } from 'components/subscriptions/SubscriptionsHeader'
import { Button } from 'components/generic/Button'
import { ISubscription } from 'interfaces/subscriptionsInterface'

export const SuggestedSubscriptionsPage = () => {
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([])
  const [addMode, setAddMode] = useState<boolean>(false)

  const getSubscriptions = async () => {
    try {
      const subscriptionsFetch = await subscriptionsController.getSubscriptions()
      setSubscriptions(subscriptionsFetch.subscriptions)
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    getSubscriptions()
  }, [])

  return (
    <div className={styles.subscriptions}>
      <h1 className={styles.title}>Suggested Subscriptions</h1>
      <SubscriptionsHeader subscriptions={subscriptions} />
      {addMode && <AddSubscription setMode={() => setAddMode(false)} reload={getSubscriptions} />}
      {addMode ? (
        <Button className={styles.addToggle} onClick={() => setAddMode(false)}>
          Cancel
        </Button>
      ) : (
        <Button className={styles.addToggle} onClick={() => setAddMode(true)}>
          New Subscription
        </Button>
      )}
      <div className={styles.subscriptionList}>
        {subscriptions.map((subscription) => (
          <Subscription
            key={subscription.appId}
            getSubscriptions={getSubscriptions}
            subscription={subscription}
          />
        ))}
      </div>
    </div>
  )
}
