import React from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Tooltip } from '@mui/material'
import 'styles/components/apps/appPlatforms.scss'
import { appInterface } from 'interfaces'
import { TiTick, TiTimes } from 'react-icons/ti'
import platformLogos from 'assets/images/platforms'
import { appController } from 'controllers'
import { FiCopy } from 'react-icons/fi'
import { useSnackbar } from 'notistack'
import { IPlatforms } from 'interfaces/appInterface'
import { useNavigate } from 'react-router-dom'

function AppPlatforms({
  platforms = [],
  platformRequests = [],
  refreshApp,
  appId,
}: {
  platforms?: appInterface.IPlatform[]
  platformRequests?: appInterface.IPlatform[]
  refreshApp: () => void
  appId: string
}) {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  async function approvePlatform({ requestId }: { requestId: string }) {
    try {
      await appController.updatePlatformRequest({ appId, requestId, approve: true })
      enqueueSnackbar('Platform has been approved', { variant: 'success' })
      if (platformRequests.length < 2) navigate('/apps/pending-platforms')
      else refreshApp()
    } catch (error) {
      console.log(error)
    }
  }

  async function rejectPlatform({ requestId }: { requestId: string }) {
    try {
      await appController.updatePlatformRequest({ appId, requestId })
      enqueueSnackbar('Platform has been rejected', { variant: 'success' })
      if (platformRequests.length < 2) navigate('/apps/pending-platforms')
      else refreshApp()
    } catch (error) {
      console.log(error)
    }
  }

  function copyLink({ link }: { link: string }) {
    try {
      navigator.clipboard.writeText(link)
      enqueueSnackbar('Link Copied', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' })
    }
  }

  const deletePlatform = async (platform: keyof IPlatforms) => {
    try {
      if (appId) await appController.deletePlatform(appId, platform)
      refreshApp()
      enqueueSnackbar('Platform has been deleted', { variant: 'success' })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const getPlatformIcon = (type: string | undefined) => {
    if (!type) return platformLogos.android
    const platformType = type.toLowerCase()
    return platformType === 'ios'
      ? platformLogos.itunes
      : platformLogos[type as keyof typeof platformLogos] || platformLogos.android
  }

  return (
    <div className='appPlatforms'>
      {!!platforms.length && (
        <>
          <h3>Platforms:</h3>
          <div className='appPlatformsWrapper'>
            {platforms.map((platform: appInterface.IPlatform) => (
              <div key={platform.id} className='platformOuterBox'>
                <div className='platform-item'>
                  <img
                    src={getPlatformIcon(platform.type)}
                    alt={platform.type}
                    className='platform-icon'
                  />
                  <div className='link-box'>
                    <Tooltip title={platform.url} placement='top'>
                      <input type='text' value={platform.url} readOnly className='platform-url' />
                    </Tooltip>
                    <FiCopy
                      className='copy-icon'
                      onClick={() => {
                        copyLink({ link: platform.url ?? '' })
                      }}
                    />
                  </div>
                  <DeleteOutlineIcon
                    className='delete-button'
                    onClick={() => {
                      if (platform.type) {
                        deletePlatform(platform.type as keyof IPlatforms)
                      }
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {!!platformRequests.length && (
        <>
          <h3>Platform Requests:</h3>
          <div className='appPlatformsWrapper'>
            {platformRequests.map((platform: appInterface.IPlatform) => (
              <div key={platform.id} className='platformOuterBox'>
                <div className='platform-item'>
                  <img
                    src={getPlatformIcon(platform.type)}
                    alt={platform.type}
                    className='platform-icon'
                  />
                  <div className='link-box'>
                    <Tooltip title={platform.url} placement='top'>
                      <input type='text' value={platform.url} readOnly className='platform-url' />
                    </Tooltip>
                    <FiCopy
                      className='copy-icon'
                      onClick={() => {
                        copyLink({ link: platform.url ?? '' })
                      }}
                    />
                  </div>
                  <div className='appPlatformStatusBox'>
                    <TiTick
                      onClick={() => approvePlatform({ requestId: platform?.requestId ?? '' })}
                      className='statusButton approve'
                    />
                    <TiTimes
                      onClick={() => rejectPlatform({ requestId: platform.requestId ?? '' })}
                      className='statusButton reject'
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default AppPlatforms
