import axios from 'axios'
import Cookies from 'js-cookie'

const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001'

// Function to get domain from URL or default to localhost
export const getDomain = () => {
  try {
    const url = new URL(backendURL)
    // For localhost, don't set domain to allow default behavior
    if (url.hostname === 'localhost') {
      return undefined
    }
    return url.hostname
  } catch (e) {
    console.warn('Invalid backend URL, defaulting to no domain', e)
    return undefined
  }
}

const defaultConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
}

// Configure axios defaults
axios.defaults.withCredentials = true

const tokenInstance = axios.create({
  baseURL: `${backendURL}/admin`,
  ...defaultConfig,
})

// Add request/response logging
tokenInstance.interceptors.request.use((request) => {
  if (!isLoggedOut) {
    // Log only safe request info
    const { url, method, data } = request
    console.log('Request:', { url, method, data })
  }
  return request
})

tokenInstance.interceptors.response.use(
  (response) => {
    if (!isLoggedOut) {
      console.log('Response:', {
        status: response.status,
        data: response.data,
      })
    }
    return response
  },
  (error) => {
    if (shouldLogError(error)) {
      console.error('Response Error:', {
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
      })
    }
    return Promise.reject(error)
  },
)

// Keep track of auth state
const AUTH_STATE_KEY = 'auth_state'
let isLoggedOut = true // Start with a safe default

export const checkAuthState = () => {
  isLoggedOut = sessionStorage.getItem(AUTH_STATE_KEY) !== 'logged_in'
  return !isLoggedOut
}

// Initialize on module load
checkAuthState()

export const setLoggedOut = (state: boolean) => {
  isLoggedOut = state
  if (state) {
    sessionStorage.setItem(AUTH_STATE_KEY, 'logged_out')
    // Clear any existing cookies
    Cookies.remove('accessToken', { domain: getDomain() })
  } else {
    sessionStorage.setItem(AUTH_STATE_KEY, 'logged_in')
  }
}

const shouldLogError = (error: any) => {
  // Don't log 401 errors during logout
  if (isLoggedOut && error.response?.status === 401) {
    return false
  }
  return true
}

export const getInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    ...defaultConfig,
  })

  // Add request/response logging
  instance.interceptors.request.use((request) => {
    if (!isLoggedOut) {
      // Log only safe request info
      const { url, method, data } = request
      console.log('Request:', { url, method, data })
    }
    return request
  })

  instance.interceptors.response.use(
    (response) => {
      if (!isLoggedOut) {
        console.log('Response:', {
          status: response.status,
          data: response.data,
        })
      }
      return response
    },
    (error) => {
      if (shouldLogError(error)) {
        console.error('Response Error:', {
          status: error.response?.status,
          data: error.response?.data,
          message: error.message,
        })
      }
      return Promise.reject(error)
    },
  )

  return instance
}

export const getAuthInstance = (baseURL: string) => {
  const authInstance = axios.create({
    baseURL,
    ...defaultConfig,
  })

  // Add request/response logging and auth check
  authInstance.interceptors.request.use((request) => {
    // Don't make auth requests if not logged in
    if (isLoggedOut && request.url !== '/login') {
      return Promise.reject(new Error('Not authenticated'))
    }

    if (!isLoggedOut) {
      const { url, method, data } = request
      console.log('Request:', { url, method, data })
    }
    return request
  })

  authInstance.interceptors.response.use(
    (response) => {
      if (!isLoggedOut) {
        console.log('Response:', {
          status: response.status,
          data: response.data,
        })
      }
      return response
    },
    (error) => {
      // Handle 401 errors by marking as logged out
      if (error.response?.status === 401) {
        setLoggedOut(true)
      }

      if (shouldLogError(error)) {
        console.error('Response Error:', {
          status: error.response?.status,
          data: error.response?.data,
          message: error.message,
        })
      }
      return Promise.reject(error)
    },
  )

  // Token refresh interceptor
  authInstance.interceptors.request.use(
    async (config) => {
      // Skip token handling for login and logout requests
      if (config.url === '/login' || config.url === '/logout') {
        return config
      }

      // Check if we're explicitly logged out
      const authState = sessionStorage.getItem(AUTH_STATE_KEY)
      if (authState === 'logged_out') {
        return config
      }

      const token = Cookies.get('accessToken')
      if (!token) {
        try {
          const response = await tokenInstance.get('/token')
          if (config.headers && response.data.accessToken) {
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            // Only set domain for non-localhost
            const cookieOptions: Cookies.CookieAttributes = {
              path: '/',
              sameSite: 'Strict',
              secure: window.location.protocol === 'https:',
            }
            const domain = getDomain()
            if (domain) {
              cookieOptions.domain = domain
            }
            Cookies.set('accessToken', response.data.accessToken, cookieOptions)
            // Mark as logged in after successful token refresh
            sessionStorage.setItem(AUTH_STATE_KEY, 'logged_in')
          }
        } catch (error) {
          setLoggedOut(true)
          throw error
        }
      } else if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`
        // Ensure we're marked as logged in if we have a valid token
        sessionStorage.setItem(AUTH_STATE_KEY, 'logged_in')
      }
      return config
    },
    (error) => Promise.reject(error),
  )

  return authInstance
}
