import React from 'react'
import 'styles/components/search/suggestions.scss'
import { NoSearchResults } from '.'
import { IList } from 'interfaces/listInterface'
import { ListSearchSaveComponent } from './ListSearchSaveComponent'
import { CircularProgress } from '@mui/material'

interface ListSearchProps {
  lists?: IList[]
  saveList: (listId: string) => void
  isLoading?: boolean
}

export const ListSearch = ({ lists, saveList, isLoading }: ListSearchProps) => {
  return (
    <div className={`suggestions ${(lists?.length || isLoading) && 'active'}`}>
      {isLoading ? (
        <div className="loading-container">
          <CircularProgress size={24} />
        </div>
      ) : lists && lists.length > 0 ? (
        lists.map((list) => (
          <ListSearchSaveComponent key={list.uuid} listData={list} saveList={saveList} />
        ))
      ) : (
        <NoSearchResults />
      )}
    </div>
  )
}
