import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import 'styles/components/list/miniListPreview.scss'
import { ListModal } from 'components/list'
import { appInterface, listInterface } from 'interfaces'
import { appController, listController } from 'controllers'
import { Button } from 'components/generic/Button'

interface MiniListPreviewProps {
  listData: listInterface.IListExtended
  reloadLists: () => void
  removeList?: (listId: string) => void
}

function MiniListPreview({ listData, reloadLists, removeList }: MiniListPreviewProps) {
  const [apps, setApps] = useState<appInterface.IApp[]>()
  const [showModal, setShowModal] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  async function fetchApps() {
    let appFetch: appInterface.IApp[]

    try {
      appFetch = await appController.getManyAppsById(listData.apps, 5)
      setApps(appFetch)
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  async function deleteList() {
    try {
      await listController.deleteList({ uuid: listData.uuid })
      enqueueSnackbar('List has been deleted', { variant: 'success' })
      return reloadLists()
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    fetchApps()
  }, [listData])

  return (
    <>
      {showModal && (
        <ListModal
          deleteList={() => deleteList()}
          updateLists={() => reloadLists()}
          hideModal={() => setShowModal(false)}
          appsData={apps}
          listData={listData}
        />
      )}
      <div className='miniListPreview' key={listData.uuid}>
        <div className='listTextBox'>
          <div
            onClick={() => setShowModal(true)}
            tabIndex={0}
            onKeyDown={() => setShowModal(false)}
            role='button'
            className='listUrl'
          >
            <h1 className='title'>{listData.name}</h1>
            <span className='counter'>
              {listData.apps.length} Apps {listData.username && `- ${listData.username}`}
            </span>
          </div>
          {removeList && <Button onClick={() => removeList(listData.uuid)}>Remove</Button>}
        </div>
      </div>
    </>
  )
}

export default MiniListPreview
