import { adminInterface } from 'interfaces'
import { errorHelper } from 'helpers'
import { getAuthInstance, getInstance, setLoggedOut, getDomain } from 'utils/axiosInstance'
import Cookies from 'js-cookie'

const backendURL = process.env.REACT_APP_BACKEND_URL
const instance = getInstance(`${backendURL}/admin`)
const authInstance = getAuthInstance(`${backendURL}/admin`)

export async function login(adminLoginData: adminInterface.IAdminLogin) {
  try {
    const loginPayload = {
      adminLoginData: {
        email: String(adminLoginData.email).trim(),
        password: String(adminLoginData.password).trim()
      }
    }
    
    const response = await instance.post('/login', loginPayload)
    
    // Validate that we received proper authentication data
    if (!response.data || !response.data.accessToken) {
      throw new errorHelper.CodeError('Invalid authentication response', 401)
    }
    
    // Set auth token in cookie with proper security settings
    const cookieOptions: Cookies.CookieAttributes = {
      path: '/',
      sameSite: 'Strict',
      secure: window.location.protocol === 'https:'
    }
    const domain = getDomain()
    if (domain) {
      cookieOptions.domain = domain
    }
    
    Cookies.set('accessToken', response.data.accessToken, cookieOptions)
    
    // Only mark as logged in after confirming valid auth response
    setLoggedOut(false)
    sessionStorage.setItem('auth_state', 'logged_in')
    
    return response.data
  } catch (error: any) {
    console.error('Login error:', {
      data: error.response?.data,
      status: error.response?.status,
      message: error.message
    })
    const message = error.response?.data?.message || error.response?.data || 'Login failed'
    throw new errorHelper.CodeError(message, error.response?.status || 500)
  }
}

export async function getAdminData() {
  try {
    const response = await authInstance.get('/')
    return response.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function register(adminData: adminInterface.INewAdmin) {
  try {
    await instance.post('/', JSON.stringify({ adminData }))
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function getBasicAnalytics() {
  try {
    const response = await authInstance.get('/analytics/basic')
    return response.data
  } catch (error: any) {
    throw new errorHelper.CodeError(error.response.data, 500)
  }
}

export async function logout() {
  try {
    // Mark as logged out first to prevent unnecessary token refresh attempts
    setLoggedOut(true)
    sessionStorage.setItem('auth_state', 'logged_out')
    
    // Clear all auth cookies on client side with same settings they were set with
    const cookieOptions: Cookies.CookieAttributes = {
      path: '/',
      sameSite: 'Strict',
      secure: window.location.protocol === 'https:'
    }
    const domain = getDomain()
    if (domain) {
      cookieOptions.domain = domain
    }

    // Remove both tokens
    Cookies.remove('accessToken', cookieOptions)
    Cookies.remove('refreshToken', cookieOptions)
    
    // Let the server handle its cookie removal
    try {
      await authInstance.post('/logout')
    } catch (error: any) {
      // Ignore 401 errors since they're expected during logout
      if (error.response?.status !== 401) {
        console.warn('Logout notification failed:', error.message)
      }
    }

    // Clear all storage
    sessionStorage.clear()
    
    // Redirect to login page
    window.location.href = '/login'
  } catch (error: any) {
    console.error('Logout error:', error)
    throw error
  }
}
