import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { IUser } from 'interfaces/userInterface'
import DoneIcon from '@mui/icons-material/Done'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'

interface UserTableProps {
  users: IUser[]
  miniList?: boolean
}

const StyledPaper = styled(Paper)({
  backgroundColor: '#1a202c',
  color: '#e2e8f0',
  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  '& .MuiTableCell-root': {
    color: '#e2e8f0',
    borderColor: '#2d3748',
    padding: '16px',
  },
  '& .MuiTableHead-root .MuiTableCell-root': {
    backgroundColor: '#2d3748',
    color: '#90cdf4',
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    letterSpacing: '0.05em',
  },
  '& .MuiTableRow-root': {
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#2d3748',
      '& .MuiTableCell-root': {
        color: '#90cdf4',
      }
    },
  },
  '& .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd)': {
    backgroundColor: '#1f2937',
  },
  '& .MuiSvgIcon-root': {
    color: '#48bb78',
  },
})

export default function UserTable({ users, miniList }: UserTableProps) {
  const navigate = useNavigate()
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
      <TableContainer component={StyledPaper} style={{ width: miniList ? 900 : 1400 }}>
        <Table sx={{ minWidth: 650 }} aria-label='users table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Email</TableCell>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>Username</TableCell>
              {!miniList && (
                <>
                  <TableCell align='center'>Registered</TableCell>
                  <TableCell align='center'>Followed Lists</TableCell>
                  <TableCell align='center'>Owned Lists</TableCell>
                  <TableCell align='center'>Last Login</TableCell>
                  <TableCell align='center'>Email Verified</TableCell>
                  <TableCell align='center'>Waitlist</TableCell>
                </>
              )}
              <TableCell align='left'>UUID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                onClick={() => navigate(`/users/${user.uuid}`)}
                key={user.uuid}
                style={{ cursor: 'pointer' }}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align='left'>{user.email}</TableCell>
                <TableCell align='left'>{user.name}</TableCell>
                <TableCell align='left'>{user.username}</TableCell>
                {!miniList && (
                  <>
                    <TableCell align='center'>
                      {user.timestamp ? new Date(user.timestamp).toDateString() : '-'}
                    </TableCell>
                    <TableCell align='center'>{user.followedLists?.length}</TableCell>
                    <TableCell align='center'>{user.lists.length}</TableCell>
                    <TableCell align='center'>
                      {user.lastLogin ? new Date(user.lastLogin).toDateString() : '-'}
                    </TableCell>
                    <TableCell align='center'>
                      {user.config?.onboarding?.emailVerified ? <DoneIcon /> : null}
                    </TableCell>
                    <TableCell align='center'>
                      {user.config?.waitlist?.isOnWaitlist ? <DoneIcon /> : null}
                    </TableCell>
                  </>
                )}
                <TableCell align='left'>{user.uuid}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
