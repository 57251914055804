/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import 'styles/components/list/listPreview.scss'
import { appInterface, listInterface } from 'interfaces'
import { Badge, ListModal } from 'components/list'
import { AppList } from 'components/apps'
import { appController, listController } from 'controllers'

interface ListPreviewProps {
  listData: listInterface.IList
  reloadLists: () => void
}

function ListPreview({ listData, reloadLists }: ListPreviewProps) {
  const [apps, setApps] = useState<appInterface.IApp[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [showModal, setShowModal] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  async function fetchApps() {
    let appFetch: appInterface.IApp[]

    try {
      appFetch = await appController.getManyAppsById(listData.apps, 5)
      setApps(appFetch)
      return setLoading(false)
    } catch (error: any) {
      setLoading(false)
      return enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  async function deleteList() {
    try {
      await listController.deleteList({ uuid: listData.uuid })
      enqueueSnackbar('List has been deleted', { variant: 'success' })
      return reloadLists()
    } catch (error: any) {
      return enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    fetchApps()
  }, [listData])

  return (
    <>
      {showModal && (
        <ListModal
          deleteList={() => deleteList()}
          updateLists={() => reloadLists()}
          hideModal={() => setShowModal(false)}
          appsData={apps}
          listData={listData}
        />
      )}
      <div className='listPreview' key={listData.uuid}>
        <div className='listTextBox'>
          <div
            onClick={() => setShowModal(true)}
            tabIndex={0}
            onKeyDown={() => setShowModal(false)}
            role='button'
            className='listUrl'
          >
            <h1 className='title'>{listData.name}</h1>
            <span className='counter'>{listData.apps.length} Apps</span>
          </div>
          {listData.isPublic && <Badge label='Public' />}
        </div>
        <div className='listAppBox'>{apps && listData && !loading && <AppList apps={apps} />}</div>
      </div>
    </>
  )
}

export default ListPreview
