import React from 'react'
import styles from 'styles/components/list/Badge.module.scss'

export const Badge = ({ label }: { label: string }) => {
  return (
    <div className={styles.badge}>
      <span className={styles.text}>{label}</span>
    </div>
  )
}
