import React, { useState, useEffect } from 'react'
import styles from './FeaturedListsPage.module.scss'
import { listController } from 'controllers'
import { listInterface } from 'interfaces'
import { MiniListPreview } from 'components/list'
import { listHelper } from 'helpers'
import { useSnackbar } from 'notistack'
import { ListSearch } from 'components/search/ListSearch'
import { IList } from 'interfaces/listInterface'

export const FeaturedListsPage = () => {
  const [search, setSearch] = useState('')
  const [lists, setLists] = useState<IList[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const [foundLists, setFoundLists] = useState<IList[]>()
  const { enqueueSnackbar } = useSnackbar()

  const findLists = async (search: string) => {
    setSearchLoading(true)
    try {
      const foundLists = await listController.getListsByName(search)
      setFoundLists(foundLists)
    } catch (error: any) {
      enqueueSnackbar(error.message || 'Failed to search lists', { variant: 'error' })
      setFoundLists([])
    } finally {
      setSearchLoading(false)
    }
  }

  const getFeaturedLists = async () => {
    try {
      const listsFetch = await listController.getFeaturedLists()
      setLists(listsFetch)
    } catch (error: any) {
      enqueueSnackbar(error.message || 'Failed to fetch featured lists', { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  const addFeaturedList = async (listId: string) => {
    try {
      await listController.addFeaturedList(listId)
      await getFeaturedLists()
      setSearch('')
    } catch (error: any) {
      enqueueSnackbar(error.message || 'Failed to add featured list', { variant: 'error' })
    }
  }

  const deleteFeaturedList = async (listId: string) => {
    try {
      await listController.deleteFeaturedList(listId)
      await getFeaturedLists()
    } catch (error: any) {
      enqueueSnackbar(error.message || 'Failed to delete featured list', { variant: 'error' })
    }
  }

  useEffect(() => {
    getFeaturedLists()
  }, [])

  useEffect(() => {
    if (search.length > 2) findLists(search)
    else setFoundLists([])
  }, [search])

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <div className={styles.featuredListsPage}>
      <h1 className={styles.featuredListsPage}>Featured Lists</h1>
      <div className={styles.searchWrapper}>
        <input
          className={styles.searchField}
          value={search}
          onChange={(v) => setSearch(v.target.value)}
          placeholder="Search lists..."
        />
        <ListSearch saveList={addFeaturedList} lists={foundLists} isLoading={searchLoading} />
      </div>
      <div className={styles.listBox}>
        {lists?.map((list) => (
          <div key={list.uuid} className={styles.listWrapper}>
            <MiniListPreview
              removeList={deleteFeaturedList}
              reloadLists={getFeaturedLists}
              listData={list}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
