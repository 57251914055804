import React from 'react'
import { createRoot } from 'react-dom/client'
import { SnackbarProvider } from 'notistack'
import 'styles/index.scss'
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import App from 'App'

axios.defaults.withCredentials = true

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </React.StrictMode>,
)
