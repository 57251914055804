import React, { useState, useEffect } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import {
  AppPage,
  NotFoundPage,
  NewAppPage,
  LoginPage,
  DashboardPage,
  ListPage,
  PublicLists,
  SearchPage,
  AllUsersPage,
  AllAppsPage,
  AllListsPage,
  UserPage,
  EditAppPage,
  AllPendingAppChangePage,
  PendingAppChangePage,
  PendingPlatforms,
  PendingPlatformsChangePage,
  Attributes,
} from 'pages'
import { adminController } from 'controllers'
import { Menu } from 'components/generic'
import 'styles/App.scss'
import AppReports from 'pages/AppReports'
import AppReportsPage from 'pages/AppReportsPage'
import Logs from 'pages/Logs'
import { SuggestedSubscriptionsPage } from 'pages/Subscriptions/SuggestedSubscriptionsPage'
import { FeaturedListsPage } from 'pages/FeaturedLists/FeaturedListsPage'
import { checkAuthState, setLoggedOut } from 'utils/axiosInstance'

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(checkAuthState())
  const navigate = useNavigate()
  const location = useLocation()

  const executeLogin = (state: boolean) => {
    setIsLoggedIn(state)
    setLoggedOut(!state) // Sync with axios instance
    sessionStorage.setItem('auth_state', state ? 'logged_in' : 'logged_out')
    if (!state) {
      // Clear user data on logout
      sessionStorage.removeItem('userData')
    }
  }

  const refreshUser = async () => {
    try {
      // Skip refresh if we're explicitly logged out
      if (sessionStorage.getItem('auth_state') === 'logged_out') {
        return
      }

      const userFetch = await adminController.getAdminData()
      if (!userFetch && !['/login', '/register', '/'].some((cur) => location.pathname === cur)) {
        navigate('/login')
      }
      if (!userFetch && isLoggedIn) {
        executeLogin(false)
      } else if (userFetch) {
        executeLogin(true)
        sessionStorage.setItem('userData', JSON.stringify(userFetch))
      }
    } catch (error) {
      console.error('Error refreshing user:', error)
      executeLogin(false)
      if (!['/login', '/register', '/'].includes(location.pathname)) {
        navigate('/login')
      }
    }
  }

  useEffect(() => {
    // Only refresh if we're not explicitly logged out
    if (sessionStorage.getItem('auth_state') !== 'logged_out') {
      refreshUser()
    }
  }, [location.pathname]) // Only refresh on route changes

  // Add effect to check auth state on mount and auth storage changes
  useEffect(() => {
    const handleStorageChange = () => {
      const newAuthState = checkAuthState()
      if (newAuthState !== isLoggedIn) {
        setIsLoggedIn(newAuthState)
      }
    }

    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [isLoggedIn])

  return (
    <div className='App'>
      <header>
        <Menu isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      </header>
      <Routes>
        <Route path='/' element={<DashboardPage isLoggedIn={isLoggedIn} />} />
        <Route path='/users' element={<AllUsersPage isLoggedIn={isLoggedIn} />} />
        <Route path='/users/:uuid' element={<UserPage isLoggedIn={isLoggedIn} />} />
        <Route path='/lists' element={<AllListsPage isLoggedIn={isLoggedIn} />} />
        <Route path='/apps' element={<AllAppsPage isLoggedIn={isLoggedIn} />} />
        <Route path='/apps/pending' element={<AllAppsPage pending isLoggedIn={isLoggedIn} />} />
        <Route
          path='/apps/pending-changes'
          element={<AllPendingAppChangePage isLoggedIn={isLoggedIn} />}
        />
        <Route
          path='/apps/pending-changes/:uuid'
          element={<PendingAppChangePage isLoggedIn={isLoggedIn} />}
        />
        <Route path='/apps/attributes' element={<Attributes isLoggedIn={isLoggedIn} />} />
        <Route
          path='/apps/pending-platforms'
          element={<PendingPlatforms isLoggedIn={isLoggedIn} />}
        />
        <Route
          path='/apps/pending-platforms/:uuid'
          element={<PendingPlatformsChangePage isLoggedIn={isLoggedIn} />}
        />
        <Route path='/apps/app-reports' element={<AppReports isLoggedIn={isLoggedIn} />} />
        <Route
          path='/apps/app-reports/:uuid'
          element={<AppReportsPage isLoggedIn={isLoggedIn} />}
        />
        <Route path='/suggested-subscriptions' element={<SuggestedSubscriptionsPage />} />
        <Route path='/featured-lists' element={<FeaturedListsPage />} />
        <Route path='/404' element={<NotFoundPage />} />
        <Route path='/new-app' element={<NewAppPage />} />
        <Route path='/app/:uuid' element={<AppPage />} />
        <Route path='/search' element={<SearchPage />} />
        <Route path='/public-lists' element={<PublicLists />} />
        <Route path='/list/:uuid' element={<ListPage />} />
        <Route path='/app/:uuid/edit' element={<EditAppPage />} />
        <Route
          path='/login'
          element={<LoginPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />}
        />
        {/* <Route path="/register" element={<RegisterPage />} /> */}
        <Route path='/logs' element={<Logs isLoggedIn={isLoggedIn} />} />
      </Routes>
    </div>
  )
}

export default App
