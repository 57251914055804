import React from 'react'
import 'styles/components/generic/inputField.scss'

function InputField({
  name,
  label,
  value,
  handleChange,
  placeholder,
  maxLength,
  type,
}: {
  name: string
  label?: string
  value?: string | number
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  maxLength?: number
  type?: 'text' | 'currency'
}) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'currency') {
      const value = e.target.value;
      // Allow only numbers and one decimal point
      if (!/^\d*\.?\d{0,2}$/.test(value)) {
        return;
      }
    }
    handleChange(e);
  };

  return (
    <div className='inputField'>
      {label && (
        <label htmlFor={name} className='label'>
          {label}
        </label>
      )}
      <input
        id={name}
        name={name}
        type='text'
        className='input'
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        maxLength={maxLength}
        inputMode={type === 'currency' ? 'decimal' : 'text'}
      />
    </div>
  )
}

export default InputField
