import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineUser, AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import { FiLogOut } from 'react-icons/fi'
import { MdOutlineViewList } from 'react-icons/md'
import 'styles/components/generic/menu.scss'
import { adminController } from 'controllers'

interface MenuProps {
  isLoggedIn: boolean
  setIsLoggedIn: (value: boolean) => void
}

function Menu({ isLoggedIn, setIsLoggedIn }: MenuProps) {
  const navigate = useNavigate()

  const goTo = (path: string) => {
    navigate(path)
  }

  const logout = async () => {
    await adminController.logout()
    setIsLoggedIn(false)
    navigate('/login')
  }

  return (
    <nav className='menu'>
      <div className='logoBox'>
        <Link className='logo' to='/'>
          <svg
            className="image"
            viewBox="0 0 120 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 30L20 10L30 30M15 20H25"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <text
              x="40"
              y="25"
              className="logo-text"
              fill="currentColor"
              style={{ fontSize: '18px', fontFamily: 'system-ui, -apple-system, sans-serif' }}
            >
              Maya
            </text>
          </svg>
        </Link>
      </div>
      <div className='widgetBox'>
        {isLoggedIn ? (
          <>
            <div className='widget-container' title='Create New App'>
              <AiOutlinePlus onClick={() => goTo('/new-app')} className='widget' />
            </div>
            <div className='widget-container' title='Search'>
              <AiOutlineSearch onClick={() => goTo('/search')} className='widget' />
            </div>
            <div className='widget-container' title='Public Lists'>
              <MdOutlineViewList onClick={() => goTo('/public-lists')} className='widget' />
            </div>
            <div className='widget-container' title='Profile'>
              <AiOutlineUser onClick={() => goTo('/')} className='widget' />
            </div>
            <div className='widget-container' title='Logout'>
              <FiLogOut onClick={logout} className='widget' />
            </div>
          </>
        ) : (
          <div className='widget-container' title='Login'>
            <AiOutlineUser onClick={() => goTo('/login')} className='widget' />
          </div>
        )}
      </div>
    </nav>
  )
}

export default Menu
