import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { attributeController } from 'controllers'
import { attributeInterface } from 'interfaces'

import styles from './Attributes.module.scss'
import { AttributeList } from 'components/attributes'
import { AddEditAttribute } from 'components/attributes/AddEditAttribute'

function Attributes({ isLoggedIn }: { isLoggedIn: boolean }) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentAttributes, setCurrentAttributes] = useState<attributeInterface.IAttribute[]>([])

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate])

  async function getAllAttributes() {
    try {
      const attributesFetch = await attributeController.getCustomAttributes()
      setCurrentAttributes(attributesFetch)
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
    return setIsLoading(false)
  }

  async function deleteAttribute(uuid: string) {
    try {
      const attributesFetch = await attributeController.deleteAttribute(uuid)
      setCurrentAttributes(attributesFetch)
      enqueueSnackbar('Attribute Deleted', { variant: 'success' })
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
    return setIsLoading(false)
  }

  useEffect(() => {
    getAllAttributes()
  }, [])

  return isLoading ? (
    <div className={styles.attributes} role="status" aria-busy="true">
      <div className={styles.skeleton} style={{ width: '60%' }} />
      <div className={styles.skeleton} style={{ width: '40%' }} />
      <div className={styles.skeleton} style={{ width: '80%' }} />
      <div className={styles.skeleton} style={{ width: '70%' }} />
    </div>
  ) : (
    <div className={styles.attributes}>
      <h1 className={styles.title}>Custom Attributes</h1>
      <div className={styles.attributeBox}>
        <AttributeList
          deleteAttribute={(uuid: string) => deleteAttribute(uuid)}
          attributes={currentAttributes}
          setCurrentAttributes={setCurrentAttributes}
        />
      </div>
    </div>
  )
}

export default Attributes
